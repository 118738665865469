
<template>

    <div class="mister">

        <div class="uk-card uk-card-default uk-card-body ">
            <h1 class="uk-card-title uk-margin-large-bottom uk-flex uk-flex-column uk-flex-between uk-gap-m uk-flex-wrap">
                <slot name="pageTitle"><span>{{ translate('sgirot.summary.mainTitle') }}</span></slot>
                <div class="expert-commission-summary uk-padding-small uk-background-muted" v-if="expertCommissionData">
                    <strong v-if="isSuperAdmin">{{ translate('sgirot.project.expertSummaryDueCommissionTitleForAdmin') }}</strong>
                    <strong v-if="isExpert">{{ translate('sgirot.project.expertSummaryDueCommissionTitleForExpert') }}</strong>
                    <br/>
                    <strong
                        style="width: 120px;display:inline-block;">{{
                            translate('sgirot.project.expertSummaryDueCommission')
                        }}: </strong><strong>{{ translateNumber(expertCommissionData.dueCommission, 'decimal') }}</strong>
                    <br/>
                    <strong
                        style="width: 120px;display:inline-block;">{{
                            translate('sgirot.project.expertSummaryPayedCommission')
                        }}: </strong><strong>{{ translateNumber(expertCommissionData.paidCommission , 'decimal') }}</strong>
                    <br/>
                    <strong
                        style="width: 120px;display:inline-block;">{{
                            translate('sgirot.project.expertSummaryTotalCommission')
                        }}: </strong><strong>{{ translateNumber(expertCommissionData.totalCommission , 'decimal') }}</strong>
                </div>

            </h1>
            <div class="page-content">
                <crud-list
                        ref="crudList"
                        :apiSource="'project/summary'"
                        :enableSort="false"
                        :enableActions="false"
                        :enableRowActions="false"
                        :enableMassActions="false"
                        :enableIdColumn="false"
                        :subject="'summary'"
                        :tableColumns="tableColumns"
                        :filterFields="filterFields">
                </crud-list>
            </div>
        </div>

    </div>
</template>

<script>
import CrudList from '@/client/applications/sgirot/components/crud/List.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import StatusPill from '@/client/applications/sgirot/components/etc/StatusPill.vue';

export default {

    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}  = useMeta({
                                  title: useI18n().t('sgirot.summary.mainTitle')
                              }).meta
        return {asyncOps, asyncOpsReady, asyncStatus, meta}


    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data() {
        return {
            entityType  : 'project',
            rawExpertCommissionData: false,
            tableColumns: {
                'monthYear'       : {},
                'totalCount'      : {},
                'dueCommission'   : {
                    cellFormatter : function (val) {
                        if ( ! val) {
                            return '0';
                        }

                        return parseInt(val);
                    }
                },
                'paidCommission'  : {
                    cellFormatter : function (val) {
                        if ( ! val) {
                            return '0';
                        }

                        return parseInt(val);
                    }
                },
                'totalCommission' : {
                    cellFormatter : function (val) {
                        if ( ! val) {
                            return '0';
                        }

                        return parseInt(val);
                    }
                },
            },
            filterFields : {

            },
        }
    },
    computed : {
        isSuperAdmin() {
            let profile = this.$store.getters['user/profile'];
            return profile && profile.isSuperAdmin;
        },
        isExpert() {
            let profile = this.$store.getters['user/profile'];
            return profile && profile.isExpert;
        },
        isSource() {
            let profile = this.$store.getters['user/profile'];
            return profile && profile.isSource;
        },
        expertCommissionData() {
            if ( ! this.rawExpertCommissionData || ! Array.isArray(this.rawExpertCommissionData)) {
                return false;
            }

            let data = {'totalCommission': 0, 'dueCommission': 0, 'paidCommission': 0}

            this.rawExpertCommissionData.forEach((month) => {
                data.totalCommission += month.totalCommission;
                data.dueCommission += month.dueCommission;
                data.paidCommission += month.paidCommission;
            });

            return data;
        },
    },
    components: {
        CrudList
    },
    mounted () {
        this.$refs.crudList.forceSetSorting({dueDate: 'DESC'});

        if (this.isExpert) {
            this.setExpertCommissionDataFromApi(this.$store.getters['user/profile'].expertId);
        }
    },
    methods : {

        async setExpertCommissionDataFromApi (expertId = 0, dateRangeStart = '1970-01-01', dateRangeEnd = '9999-01-01') {
            let data = {
                expertId      : expertId,
                dateRangeStart: dateRangeStart,
                dateRangeEnd  : dateRangeEnd,
            }
            let result;

            try {
                result = await this.asyncOps.asyncCall('project/summary', data, {method: 'get'});
            } catch (e) {
                console.log(e);
            }

            this.rawExpertCommissionData = result.data.items;
        },

    },
    layout    : 'dashboard',

}
</script>

<style lang="scss" scoped>
 .header-action {
     padding: 6px 12px;
     border-radius: 12px;
     background: var(--global-primary-background);
     color: var(--global-background);
     cursor: pointer;
     transition: 150ms ease;

     &:hover {
         opacity: 0.8;
     }
 }

 .expert-commission-summary {
     font-size : var(--global-font-size);
 }
</style>

<template>
    <div class="status-pill" :class="dynamicWrapperClass">
        {{statusText}}
    </div>
</template>

<script>
    export default {
        props : {
            status: {
                type: [Number, Boolean],
                default: false
            }
        },
        computed: {
            safeCode() {
                return this.status ? this.status : '0';
            },
            statusText() {
                let code = this.status ? this.status : '0';
                return this.translate('sgirot.statuses.' + this.safeCode);
            },
            dynamicWrapperClass () {
                return 'status-color-' + this.safeCode;
            }
        }
    }
</script>

<style lang="scss" scoped>

.status-pill {
    display: inline-block;
    padding: 6px 20px;
    border-radius: 30px;
    color: var(--global-inverse-color);

    &.status-color-0 {
        background-color: var(--global-secondary-background);
    }

    &.status-color-1 {
        background-color: var(--global-primary-background);
    }

    &.status-color-2 {
        background-color: #ada397;
        color: var(--global-color);
    }

    &.status-color-3 {
        background-color: var(--global-danger-background);
    }

    &.status-color-4 {
        background-color: #ff7b25;
    }

    &.status-color-5 {
        background-color: #b5e7A0;
        color: var(--global-color);

    }

    &.status-color-6 {
        background-color: #f6f2f3;
        color: var(--global-color);
    }

    &.status-color-7 {
        background-color: #1E87F0FF;
    }


    &.status-color-8 {
        background-color: #3e4444;
        color: var(--global-background);
    }

    &.status-color-9 {
        background-color: var(--global-success-color);
    }

}


</style>
